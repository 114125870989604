import React from "react";
import { SectionWhyLIMS } from "../SectionWhyLims";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./StockTracker.module.scss";
import { WhyLimsProps } from "../SectionWhyLims/data";

export const StockTracker = () => {
	const props: WhyLimsProps = {
		title: "Stock tracker for biopharma lab inventory management",
		// starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"Genemod's lab inventory tracking solution enables enhanced operational efficiency and significant time savings, designed specifically for refined laboratory management.",
		list: [
			{
				text: "Efficient management of samples and consumables",
				icon: STAR,
				id: 1,
			},
			{
				text: "Streamlined restocking process",
				icon: STAR,
				id: 2,
			},

			{
				text: "Integration of research workflows and databases",
				icon: STAR,
				id: 3,
			},
		],
		link:"DASHBOARD",
		image: (
			<div className={styles.imageWrapper}>
				<StaticImage
					loading="eager"
					src={"../../../assets/images/lims/order-collaboration.png"}
					alt="Basic Protocols"
				/>
			</div>
		),
		gap: "gapLg",
		// sectionWrapperOverwrite:styles.sectionWrapperOverwrite
	};
	return <div id="inventory-management"> <SectionWhyLIMS {...props} /></div>
};
