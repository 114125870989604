import React from "react";
import { SectionWhyLIMS } from "../SectionWhyLims";
import STAR from "@assets/icons/Lims/star.svg";
import { StaticImage } from "gatsby-plugin-image";
import STAR_GRAY_1 from "@assets/icons/Lims/star-gray-1.svg";
import SVG from "react-inlinesvg";
import * as styles from "./AllInOne.module.scss";
import { WhyLimsProps } from "../SectionWhyLims/data";

export const AllInOne = () => {
	const props: WhyLimsProps = {
		title: "All-in-one LIMS platform",
		starOnTitle: <SVG src={STAR_GRAY_1} className={styles.starGray1} />,
		description:
			"Genemod LIMS provides full integration and optimization for all kinds of laboratories and softwares.",
		list: [
			{
				text: "Exclusive access to Genemod public API",
				icon: STAR,
				id: 1,
			},
			{
				text: "100% commitment to data protection",
				icon: STAR,
				id: 2,
			},

			{
				text: "Compliance with global standards (21 CFR Part 11)",
				icon: STAR,
				id: 3,
			},
		],
		link: "INTEGRATIONS",
		image: (
			<div className={styles.imageWrapper}>
				<StaticImage
					loading="eager"
					src={"../../../assets/images/lims/platform-dashboard.png"}
					alt="Basic Protocols"
				/>
			</div>
		),
	};
	return (
		<div id="all-in-one-platform">
			<SectionWhyLIMS {...props} />
		</div>
	);
};
